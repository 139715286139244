import { useEffect, useMemo, useRef, useState } from "react";
import { useWidgetState } from "../../../containers/WidgetWrapper/wrapper";
import { Box, Grid, SelectChangeEvent, Select, MenuItem, Typography, Button } from "@mui/material";
import { KeyTermsState } from "../../../containers/WidgetWrapper/states";
import DocumentAnnotatorComponent from "./DocumentAnnotatorComponent";
import { useKeyTermGroupState } from "../../../containers/TakerDocumentState/KeyTermGroupState";
import { useUserScopedAppData } from "../../../containers/UserScopedAppData/UserScopedAppData";

interface DocumentAnnotatorProps {
    readOnly: boolean;
}

const DocumentAnnotator = ({
    readOnly
}: DocumentAnnotatorProps) => {
    const {
        takerDocumentUpload,
        deleteTakerDocumentUploadData
    } = useKeyTermGroupState();
    const {
        getState,
        mutateState
    } = useWidgetState();
    const { isDebugger } = useUserScopedAppData();
    const documentRef = useRef<HTMLDivElement>(null);
    const [documentWidth, setDocumentWidth] = useState<number>();

    const targetFileUploadItemId = getState<KeyTermsState>().targetFileUploadItemId;

    const targetTakerDocumentUploadAnalysis = useMemo(() => {
        if (!takerDocumentUpload) {
            return;
        }

        for (const tdua of takerDocumentUpload.contentFilteringTakerDocumentUploadAnalyses) {
            if (tdua.data["file_item_id"] === targetFileUploadItemId) {
                return tdua;
            }
        }
    }, [takerDocumentUpload, targetFileUploadItemId]);

    const sortedFileOptions = useMemo(() =>
        [...(takerDocumentUpload?.fileUpload.fileUploadItems || [])].sort((a, b) => b.createdAt - a.createdAt),
        [takerDocumentUpload?.fileUpload.fileUploadItems]
    );

    useEffect(() => {
        if (!documentRef.current) {
            return
        };
        const resizeObserver = new ResizeObserver(() => {
            setDocumentWidth(documentRef.current?.offsetWidth);
        });
        resizeObserver.observe(documentRef.current);
        return () => resizeObserver.disconnect(); // clean up 
    }, []);

    return (
        <Box
            display="flex"
            flexDirection="column"
            sx={{
                height: '100%',
                width: '100%'
            }}
        >
            <Box
                sx={{
                    width: '100%'
                }}
                paddingBottom={1}
            >
                <Grid
                    container
                    justifyContent="end"
                    justifyItems="center"
                    columnSpacing={1}
                >
                    {isDebugger && (
                        <Grid item>
                            <Button
                                variant="contained"
                                color="error"
                                onClick={deleteTakerDocumentUploadData}>
                                clear all
                            </Button>
                        </Grid>
                    )}
                    <Grid item>
                        {(sortedFileOptions.length > 0) ? (
                            <Select
                                value={targetFileUploadItemId}
                                size="small"
                                onChange={(event: SelectChangeEvent) =>
                                    mutateState<KeyTermsState>({
                                        targetFileUploadItemId: event.target.value
                                    })
                                }
                                displayEmpty
                                renderValue={(v) => {
                                    let fuis = (takerDocumentUpload?.fileUpload.fileUploadItems || []).filter(fui => fui.id === v);
                                    let label = (fuis.length > 0 && fuis[0].label) || "";
                                    if (label.length > 40) {
                                        return `${label.substring(0, 40)}...`;
                                    } else if (label.length > 0) {
                                        return label;
                                    }
                                    return "select a file";
                                }}
                                autoWidth
                                label=""
                            >
                                {sortedFileOptions.map(fui => (
                                    <MenuItem value={fui.id}>
                                        {fui.label}
                                    </MenuItem>
                                ))}
                            </Select>
                        ) : (
                            <Box
                                padding={1}
                                borderRadius={2}
                            >
                                <Typography>Upload a file</Typography>
                            </Box>
                        )}
                    </Grid>
                </Grid>
            </Box>
            <div
                style={{
                    width: '100%',
                    overflow: 'auto',
                    flexGrow: 1,
                    overflowX: 'hidden'
                }}
                ref={documentRef}
            >
                {(!!targetTakerDocumentUploadAnalysis 
                    && !!targetFileUploadItemId 
                    && documentWidth !== undefined) ? (
                    <DocumentAnnotatorComponent
                        readOnly={readOnly}
                        documentWidth={documentWidth}
                        fileUploadItemId={targetFileUploadItemId}
                        takerDocumentUploadAnalysis={targetTakerDocumentUploadAnalysis}
                    />
                ) : (
                    <em>Select a file</em>
                )}
            </div>
        </Box>
    );
}

export default DocumentAnnotator;