import React, { useEffect, useState } from "react";
import { SimpleModalWrapper } from "../../dialog/wrappers/simpleModalWrapper";
import { Button, Stack, Tab, Tabs, Typography } from "@mui/material/";
import { useTakerState } from "../../../containers/TakerDocumentState/TakerDocumentState";
import { Download } from "@mui/icons-material";

import JSZip from "jszip";
import { useDeleteTakerDocumentDataMutation, useGetLatestTakerDocumentDataQuery, useGetLatestTakerDocumentUploadDataQuery, useLazyGetLatestTakerDocumentUploadDataQuery } from "../../../redux/services/takerData";
import { useReportState } from "../../../containers/TakerDocumentState/ReportState";
import { TakerDocumentUploadData } from "../../../redux/models/dataModelTypes";

const TAKER_OUTPUT_FN = "taker_output.json";
const KEY_TERM_DATA_FN = "key_term_data.json";
const QUESTIONNAIRE_DATA_FN = "questionnaire_data.json";
const REPORT_DATA_FN = "report_data.json";
const TAKER_DATA_FN = "taker_data.json";
const TAKER_METADATA_FN = "taker_metadata.json";

interface Props {
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
      style={{
        height: "90%",
      }}
    >
      {value === index && (
        children
      )}
    </div>
  );
}

function DebugModal({ open, setOpen }: Props) {
  const {

    // Raw output last recieved from server.
    takerOutput,

    // Local state, taker data.
    activeTargetTakerState,

    // Taker data.
    taker,
    takerDocumentUploads,

    // Taker metadata.
    lastSavedTimestamp,
    takerDocumentId,
    latestQuestionnaireDataId
  } = useTakerState();
  const { latestReportDataId } = useReportState();
  const [tabValue, setTabValue] = useState(0);

  const {
    data: latestReportData,
    isLoading: reportLoading
  } = useGetLatestTakerDocumentDataQuery({ takerDocumentId, contentType: "REPORT" });

  const [keyTermsTduData, setKeyTermsTduData] = useState<TakerDocumentUploadData[]>();

  const [fetchTduData] = useLazyGetLatestTakerDocumentUploadDataQuery();

  const [deleteTakerDocumentData, deleteTakerDocumentDataRes] = useDeleteTakerDocumentDataMutation();

  const handleChange = (event: React.SyntheticEvent, newValue: number) => setTabValue(newValue);

  useEffect(() => {
    const promises = [];
    if (takerDocumentUploads) {
      for (const tdu of takerDocumentUploads) {
        promises.push(fetchTduData({
          takerDocumentUploadId: tdu.id,
          contentType: "KEY_TERMS"
        }));
      }
    }

    Promise.all(promises).then((res) => {
      const newKtData: TakerDocumentUploadData[] = [];
      for (const r of res) {
        if (r.data) {
          newKtData.push(r.data);
        }
      }
      setKeyTermsTduData(newKtData);
    }).catch((error) => {
        console.error(error);
    });
  }, [takerDocumentUploads]); 
  
  const handleDownload = () => {

    // Prep blobs from local data.
    let takerOutputBlob = new Blob([JSON.stringify(takerOutput)], { type: "application/json" });
    let localTakerDataState = new Blob([JSON.stringify({
      activeTargetTakerState: activeTargetTakerState.uiDataSchema,
    })], { type: "application/json" });
    let keyTermsData = new Blob([JSON.stringify(keyTermsTduData)], { type: "application/json" });
    let reportData = new Blob([JSON.stringify(latestReportData)], { type: "application/json" });
    let takerData = new Blob([JSON.stringify({
      taker,
      takerDocumentUploads
    })], { type: "application/json" });
    let takerMetadata = new Blob([JSON.stringify({
      lastSavedTimestamp,
      takerDocumentId,
    })], { type: "application/json" });

    // Add all files to zip object.
    const zip = new JSZip();
    zip.file(TAKER_OUTPUT_FN, takerOutputBlob);

    zip.file(KEY_TERM_DATA_FN, keyTermsData);
    zip.file(QUESTIONNAIRE_DATA_FN, localTakerDataState);
    zip.file(REPORT_DATA_FN, reportData);
    zip.file(TAKER_DATA_FN, takerData);
    zip.file(TAKER_METADATA_FN, takerMetadata);

    zip.generateAsync({
      type: "blob",
      streamFiles: true,
    }).then((zipData) => {
      const link = document.createElement("a");
      link.href = window.URL.createObjectURL(zipData);
      link.download = `taker_bundle ${new Date().toString()}.zip`;
      link.click();
    });
  }

  const clearAllAnalysis = () => {
    if (taker && latestQuestionnaireDataId) {
      deleteTakerDocumentData({
        id: latestQuestionnaireDataId,
        takerDocumentId: takerDocumentId,
        takerId: taker.id
      });
    }
  };

  const clearAllReportRevisions = () => {
    if (taker && latestReportDataId) {
      deleteTakerDocumentData({
        id: latestReportDataId,
        takerDocumentId: takerDocumentId,
        takerId: taker.id
      });
    }
  };

  useEffect(() => {
    if (deleteTakerDocumentDataRes.isSuccess) {
      localStorage.removeItem(`KeyTermsState-${takerDocumentId}`);
      window.location.reload();
    }
  }, [deleteTakerDocumentDataRes]);

  return (
    <SimpleModalWrapper
      headerText=""
      open={open}
      handleClose={() => setOpen(false)}
      sx={{ width: "80vw", padding: "16px 24px 16px 24px" }}
    >
      <Tabs
        value={tabValue}
        onChange={handleChange}
        style={{
          height: "10%",
        }}
      >
        <Tab label="Diagnostics" />
        <Tab label="Variables" />
        <Tab label="Export" />
        <Tab label="Actions" />
      </Tabs>
      <TabPanel value={tabValue} index={0}>
        <pre>
          {takerOutput && JSON.stringify(takerOutput['diagnostics'], null, 4)}
        </pre>
      </TabPanel>
      <TabPanel value={tabValue} index={1}>
        <pre>
          {takerOutput && JSON.stringify(takerOutput['scoped_variables'], null, 4)}
        </pre>
      </TabPanel>
      <TabPanel value={tabValue} index={2}>
        <Typography
          paddingTop={2}
          paragraph
        >
          The bundle contains data representing the current state of the taker. It is a zip of several files
          containing various data objects and metadata that can be used to debug / import / validate the state
          of the taker.
        </Typography>
        <Button
          startIcon={<Download />}
          disabled={reportLoading || !keyTermsTduData}
          onClick={handleDownload}
        >
          Taker Bundle
        </Button>
      </TabPanel>
      <TabPanel value={tabValue} index={3}>
        <Stack spacing={1}>
          <Button
            color="error"
            variant="contained"
            onClick={clearAllAnalysis}
          >
            Clear All Analysis
          </Button>
          <Button
            color="error"
            variant="contained"
            onClick={clearAllReportRevisions}
          >
            Clear All Report Revisions
          </Button>
        </Stack>
      </TabPanel>
    </SimpleModalWrapper>
  );
}

export default DebugModal;
