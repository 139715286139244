import { createApi } from '@reduxjs/toolkit/query/react';
import { Builder, BuilderDocument, BuilderDocumentGuidance, BuilderDocumentMemoTemplate, TakerDocumentSettings } from "../models/dataModelTypes";
import { axiosBaseQuery } from '../reduxUtils/baseQuery';
import { PaginatedResponse } from '../models/commonTypes';

type ListBuildersQuery = { 
    page: number | void, 
    limit: number | void,
    ownedByUser: boolean | void,
    organizationId: string | void
};

export const buildersApi = createApi({
    reducerPath: 'buildersApi',
    baseQuery: axiosBaseQuery(),
    tagTypes: ['Builders', 'BuilderDocuments', 'DefaultTakerDocumentSettings'],
    endpoints: (build) => ({
        listBuilders: build.query<PaginatedResponse<Builder>, ListBuildersQuery>({
            query: ({page = 1, limit = 10, ownedByUser = true, organizationId = null }) => ({
                url: (organizationId) ? (
                    ownedByUser ? (
                        `builders?page=${page}&limit=${limit}&owned_by_user=${ownedByUser}&organization_id=${organizationId}`
                    ) : (
                        `builders?page=${page}&limit=${limit}&organization_id=${organizationId}`
                    )
                ) : (
                    `builders?page=${page}&limit=${limit}&owned_by_user=${ownedByUser}`
                ),
                method: "GET"
            }),            
            providesTags: (result) =>
                result
                ?
                    [
                        ...result.data.map(({ id }) => ({ type: 'Builders', id } as const)),
                        { type: 'Builders', id: 'LIST' },
                    ]
                :
                    [{ type: 'Builders', id: 'LIST' }],
        }),
        addBuilder: build.mutation<Builder, Partial<Builder>>({
            query(body) {
                const { name, description, organizationId } = body;
                const requestBody: Record<string, any> = {
                    name,
                    description,
                };
                if (organizationId) {
                    requestBody['organization_id'] = organizationId;
                }
                return {
                    url: `builders`,
                    method: 'POST',
                    data: requestBody
                }
            },
            invalidatesTags: [{ type: 'Builders', id: 'LIST' }],
        }),
        getBuilder: build.query<Builder, string>({
            query: (id) => ({
                url: `builders/${id}`,
                method: "GET"
            }),
            providesTags: (result, error, id) => [{ type: 'Builders', id }],
        }),
        updateBuilder: build.mutation<Builder, Partial<Builder>>({
            query(data) {
                const { id, ...body } = data
                return {
                    url: `builders/${id}`,
                    method: 'PUT',
                    data: body
                }
            },
            invalidatesTags: (result, error, { id }) => [{ type: 'Builders', id }],
        }),
        deleteBuilder: build.mutation<boolean, string>({
            query(id) {
                return {
                    url: `builders/${id}`,
                    method: 'DELETE',
                }
            },
            invalidatesTags: (result, error, id) => [{ type: 'Builders', id }],
        }),
        getBuilderDocument: build.query<BuilderDocument, string>({
            query: (id) => ({
                url: `builder_documents/${id}`,
                method: 'GET'
            }),
            providesTags: (result, error, id) => [{ type: 'BuilderDocuments', id }],
        }),
        updateBuilderDocument: build.mutation<BuilderDocument, Partial<BuilderDocument>>({
            query(data) {
                const { id, configuration } = data
                return {
                    url: `builder_documents/${id}`,
                    method: 'PUT',
                    data: {
                        configuration
                    }
                }
            },
            invalidatesTags: (result, error, { id }) => [{ type: 'BuilderDocuments', id }],
        }),
        addBuilderDocumentMemoTemplate: build.mutation<BuilderDocumentMemoTemplate, Partial<BuilderDocumentMemoTemplate>>({
            query(body) {
                const { builderDocumentId, data } = body;                
                return {
                    url: `builder_documents/${builderDocumentId}/memo_templates`,
                    method: 'POST',
                    data: {
                        data: data,
                        builder_document_id: builderDocumentId
                    }
                }
            },
            invalidatesTags: (result, error, { builderDocumentId }) => [{ type: 'BuilderDocuments', builderDocumentId }],
        }),
        updateBuilderDocumentMemoTemplate: build.mutation<BuilderDocumentMemoTemplate, Partial<BuilderDocumentMemoTemplate>>({
            query(body) {
                const { id, builderDocumentId, data } = body;                
                return {
                    url: `builder_documents/${builderDocumentId}/memo_templates/${id}`,
                    method: 'PUT',
                    data: {
                        data: data
                    }
                }
            },
            invalidatesTags: (result, error, { builderDocumentId }) => [{ type: 'BuilderDocuments', builderDocumentId }],
        }),
        addBuilderDocumentGuidance: build.mutation<BuilderDocumentGuidance, Partial<BuilderDocumentGuidance>>({
            query(body) {
                const { builderDocumentId, indexedGuidanceId } = body;                
                return {
                    url: `builder_documents/${builderDocumentId}/guidance`,
                    method: 'POST',
                    data: {
                        indexed_guidance_id: indexedGuidanceId,
                    }
                }
            },
            invalidatesTags: (result, error, { builderDocumentId }) => [{ type: 'BuilderDocuments', builderDocumentId }],
        }),
        removeBuilderDocumentGuidance: build.mutation<BuilderDocumentGuidance, Partial<BuilderDocumentGuidance>>({
            query(body) {
                const { id, builderDocumentId } = body;                
                return {
                    url: `builder_documents/${builderDocumentId}/guidance/${id}`,
                    method: 'DELETE'
                }
            },
            invalidatesTags: (result, error, { builderDocumentId }) => [{ type: 'BuilderDocuments', builderDocumentId }],
        }),
        getDefaultTakerDocumentSettings: build.query<TakerDocumentSettings, string>({
            query: (id) => ({
                url: `builder_documents/${id}/default_settings`,
                method: 'GET'
            }),
            providesTags: (result, error, id) => [{ type: 'DefaultTakerDocumentSettings', id }],
        }),
    }),
})

export const {
    useListBuildersQuery,
    useAddBuilderMutation,
    useGetBuilderQuery,
    useUpdateBuilderMutation,
    useDeleteBuilderMutation,
    useGetBuilderDocumentQuery,
    useUpdateBuilderDocumentMutation,
    useAddBuilderDocumentMemoTemplateMutation,
    useUpdateBuilderDocumentMemoTemplateMutation,
    useAddBuilderDocumentGuidanceMutation,
    useRemoveBuilderDocumentGuidanceMutation,
    useGetDefaultTakerDocumentSettingsQuery
} = buildersApi;