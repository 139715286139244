import { configureStore } from '@reduxjs/toolkit';
import rootReducer from "./reducers/rootReducer";
import { languageModelApi } from "./services/languageModel";
import { userApi } from "./services/user";
import { currentUserApi } from "./services/currentUser";
import { takerApi } from "./services/taker";
import { buildersApi } from "./services/builder";
import { assistantApi } from "./services/assistant";
import { organizationApi } from "./services/organization";
import { staticFileApi } from './services/staticFile';
import { indexedGuidanceApi } from './services/indexedGuidance';
import { applicationApi } from './services/application';
import { 
  fileUploadApi, 
  fileContentUploadApi 
} from './services/fileUpload';
import { takerDataApi } from './services/takerData';

export const store = configureStore({
  reducer: rootReducer,
  // Adding the api middleware enables caching, invalidation, polling,
  // and other useful features of `rtk-query`.
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware()
      .concat(languageModelApi.middleware)
      .concat(assistantApi.middleware)
      .concat(userApi.middleware)
      .concat(takerApi.middleware)
      .concat(takerDataApi.middleware)
      .concat(buildersApi.middleware)
      .concat(organizationApi.middleware)
      .concat(staticFileApi.middleware)
      .concat(indexedGuidanceApi.middleware)
      .concat(applicationApi.middleware)
      .concat(fileUploadApi.middleware)
      .concat(fileContentUploadApi.middleware)
      .concat(currentUserApi.middleware)
});

// optional, but required for refetchOnFocus/refetchOnReconnect behaviors
// see `setupListeners` docs - takes an optional callback as the 2nd arg for customization
// setupListeners(store.dispatch)
