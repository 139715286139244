import { mergeRegister } from '@lexical/utils';
import {
  COMMAND_PRIORITY_LOW,
  LexicalEditor,
  CLICK_COMMAND,
} from 'lexical';
import React, { useCallback, useEffect, useRef } from 'react';
import { IconButton } from '@mui/material';
import { Edit } from '@mui/icons-material';
import { OPEN_STATUS_COMMAND } from '../EditAnnotationPlugin';
import { getDOMRangeRect, setFloatingElemPosition } from '../common';

import './index.css';
import { useKeyTermGroupState } from '../../../../containers/TakerDocumentState/KeyTermGroupState';
import { OptionsWithExtraProps, useSnackbar } from 'notistack';

const TOP_CENTER_OPTION = {
  variant: 'info',
  anchorOrigin: {
    vertical: 'top',
    horizontal: 'center'
  }
} as OptionsWithExtraProps<'info'>;

export default function EditTermFloatingToolbar({
  editor,
  anchorElem,
  selectedElementId
}: {
  editor: LexicalEditor;
  anchorElem: HTMLElement;
  selectedElementId: string;
}): JSX.Element {
  const popupCharStylesEditorRef = useRef<HTMLDivElement | null>(null);
  const { documentKeyTermsService } = useKeyTermGroupState();

  const { enqueueSnackbar } = useSnackbar();

  const showTermViewer = useCallback(() => {
    const targetDocumentAnnotations = [];
    const targetDAIds = new Set();
    if (documentKeyTermsService.keyTerms) {
      for (const dkt of documentKeyTermsService.keyTerms) {
        if (dkt.documentAnnotations) {
          for (const da of dkt.documentAnnotations) {
            if (targetDAIds.has(da.annotationId)) {
              continue;
            }

            if (da.documentHighlights.some(dh => dh.elementId === selectedElementId)) {
              targetDocumentAnnotations.push(da);
              targetDAIds.add(da.annotationId);
            }
          }
        }
      }
    }

    if (targetDocumentAnnotations.length === 0) {
      enqueueSnackbar("No annotations found.", TOP_CENTER_OPTION);
    }
    
    editor.dispatchCommand(OPEN_STATUS_COMMAND, {
      documentAnnotations: targetDocumentAnnotations
    });
  }, [documentKeyTermsService.keyTerms]);
  
  function mouseMoveListener(e: MouseEvent) {
    if (
      popupCharStylesEditorRef?.current &&
      (e.buttons === 1 || e.buttons === 3)
    ) {
      if (popupCharStylesEditorRef.current.style.pointerEvents !== 'none') {
        const x = e.clientX;
        const y = e.clientY;
        const elementUnderMouse = document.elementFromPoint(x, y);

        if (!popupCharStylesEditorRef.current.contains(elementUnderMouse)) {
          // Mouse is not over the target element => not a normal click, but probably a drag
          popupCharStylesEditorRef.current.style.pointerEvents = 'none';
        }
      }
    }
  }
  function mouseUpListener(e: MouseEvent) {
    if (popupCharStylesEditorRef?.current) {
      if (popupCharStylesEditorRef.current.style.pointerEvents !== 'auto') {
        popupCharStylesEditorRef.current.style.pointerEvents = 'auto';
      }
    }
  }

  useEffect(() => {
    if (popupCharStylesEditorRef?.current) {
      document.addEventListener('mousemove', mouseMoveListener);
      document.addEventListener('mouseup', mouseUpListener);

      return () => {
        document.removeEventListener('mousemove', mouseMoveListener);
        document.removeEventListener('mouseup', mouseUpListener);
      };
    }
  }, [popupCharStylesEditorRef]);

  const updateTextFormatFloatingToolbar = useCallback(() => {
    const popupCharStylesEditorElem = popupCharStylesEditorRef.current;
    const nativeSelection = window.getSelection();
    if (popupCharStylesEditorElem === null) {
      return;
    }

    const rootElement = editor.getRootElement();
    if (
      nativeSelection !== null &&
      rootElement !== null &&
      rootElement.contains(nativeSelection.anchorNode)
    ) {
      const rangeRect = getDOMRangeRect(nativeSelection, rootElement);
      setFloatingElemPosition(
        rangeRect,
        popupCharStylesEditorElem,
        anchorElem
      );
    }
  }, [editor, anchorElem]);

  useEffect(() => {
    const scrollerElem = anchorElem.parentElement;

    const update = () => {
      editor.getEditorState().read(() => {
        updateTextFormatFloatingToolbar();
      });
    };

    window.addEventListener('resize', update);
    if (scrollerElem) {
      scrollerElem.addEventListener('scroll', update);
    }

    return () => {
      window.removeEventListener('resize', update);
      if (scrollerElem) {
        scrollerElem.removeEventListener('scroll', update);
      }
    };
  }, [editor, updateTextFormatFloatingToolbar, anchorElem]);

  useEffect(() => {
    editor.getEditorState().read(() => {
      updateTextFormatFloatingToolbar();
    });
    return mergeRegister(
      editor.registerUpdateListener(({ editorState }) => {
        editorState.read(() => {
          updateTextFormatFloatingToolbar();
        });
      }),
      editor.registerCommand(
        CLICK_COMMAND,
        () => {
          updateTextFormatFloatingToolbar();
          return false;
        },
        COMMAND_PRIORITY_LOW,
      ),
    );
  }, [editor, updateTextFormatFloatingToolbar]);

  return (
    <div ref={popupCharStylesEditorRef} className="floating-text-format-popup">
      <IconButton onClick={showTermViewer}>
        <Edit/>
      </IconButton>
    </div>
  );
}