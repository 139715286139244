import React, { useState } from "react";
import {
    Box,
    Grid,
    ListItem,
    IconButton,
    Chip,
    Divider
} from "@mui/material/";
import { ArrowUpward, ArrowDownward, Edit, LockOpen, Lock, Bolt, Delete, ContentPasteGoRounded } from "@mui/icons-material";
import { KeyTerm } from "../../../types/taker/documentkeyterms.generated";
import { KeyTermsState } from "../../../containers/WidgetWrapper/states";
import { useWidgetState } from "../../../containers/WidgetWrapper/wrapper";
import EditableSummary from "../../../components/form/EditableSummary";
import { useKeyTermGroupState } from "../../../containers/TakerDocumentState/KeyTermGroupState";
import KeyTermDetailModal from "./KeyTermDetailModal";
import ConfirmationDialog from "../../../components/dialog/GenericConfirmation";

interface KeyTermListItemProps {
    takerDocumentUploadId?: string;
    keyTerm: KeyTerm;
    myIndex: number;
    totalLength?: number;
    readOnly: boolean;
}

const KeyTermListItem = ({
    takerDocumentUploadId,
    keyTerm,
    myIndex,
    totalLength,
    readOnly,
}: KeyTermListItemProps) => {
    const { documentKeyTermsService } = useKeyTermGroupState();
    const { getState, mutateState } = useWidgetState();
    const [editOpen, setEditOpen] = useState<number>();
    const [needConfirm, setNeedConfirm] = useState<boolean>(false);

    const [importedText, setImportedText] = useState<string>();
    const [importedTextChanges, setImportedTextChanges] = useState<boolean>(false);

    const deleteClicked = () => {
        setNeedConfirm(true);
    }

    const fileUploadItemId = getState<KeyTermsState>().targetFileUploadItemId;
    const showExpandedSummaries = getState<KeyTermsState>().showExpandedSummaries;

    const combinedRefs: Record<number, string[]> = {};
    if (keyTerm.documentAnnotations) {
        for (const da of keyTerm.documentAnnotations) {
            if (da.lexicalDocumentIdentifier === fileUploadItemId) {
                if (!combinedRefs[da.page]) {
                    combinedRefs[da.page] = [];
                }
                combinedRefs[da.page].push(da.annotationId);
            }
        }
    }

    const entries = Object.entries(combinedRefs);

    return (
        <ListItem
            data-testid={`key-term-list-item-${myIndex}`}
            key={`${takerDocumentUploadId}_${myIndex}`}
            sx={{
                paddingTop: 0,
                paddingLeft: 0,
                paddingRight: 0
            }}
        >
            <Grid
                container
                padding={1}
                sx={{
                    backgroundColor: "white",
                    borderRadius: 1,
                    border: "1px solid rgb(195, 195, 195)",
                }}
            >
                <Grid
                    item
                    container
                    justifyContent="space-between"
                    alignItems="center"
                    xs={12}
                >
                    <Grid
                        item
                        container
                        justifyContent="start"
                        alignItems="center"
                        xs={6}
                    >
                        <Grid item>
                            <strong>{keyTerm.termName}</strong>
                        </Grid>
                    </Grid>
                    <Grid
                        item
                        container
                        justifyContent="end"
                        alignItems="center"
                        xs={6}
                    >
                        <Grid item>
                            <IconButton
                                data-testid={`key-term-delete-${keyTerm.termName}`}
                                disabled={readOnly}
                                size="small"
                                onClick={() =>
                                    deleteClicked()
                                }
                            >
                                <Delete />
                            </IconButton>
                        </Grid>
                        <Grid item>
                            <IconButton
                                data-testid={`key-term-import-to-summary-${keyTerm.termName}`}
                                disabled={readOnly}
                                size="small"
                                onClick={() => {
                                    let fullText = "";
                                    keyTerm?.documentAnnotations?.forEach(function (annotationObject) {
                                        let text = documentKeyTermsService.getTextForAnnotation(annotationObject.annotationId) 
                                        fullText = fullText + " " + text;
                                    });
                                    setImportedText(fullText);
                                    setImportedTextChanges(!importedTextChanges);
                                }
                                }
                            >
                                <ContentPasteGoRounded />
                            </IconButton>
                        </Grid>
                        {(!readOnly) && (
                            <Grid item>
                                <IconButton
                                    data-testid={`key-term-edit-${keyTerm.termName}`}
                                    disabled={readOnly}
                                    size="small"
                                    onClick={() => setEditOpen(myIndex)}
                                >
                                    <Edit />
                                </IconButton>
                            </Grid>
                        )}
                        {!readOnly && (
                            <Grid item>
                                <IconButton
                                    data-testid={`key-term-up-${keyTerm.termName}`}
                                    disabled={myIndex === 0 || readOnly}
                                    size="small"
                                    onClick={() =>
                                        documentKeyTermsService.shiftKeyTermUp(keyTerm.termName)
                                    }
                                >
                                    <ArrowUpward />
                                </IconButton>
                            </Grid>
                        )}
                        {(!readOnly && totalLength !== undefined) && (
                            <Grid item>
                                <IconButton
                                    data-testid={`key-term-down-${keyTerm.termName}`}
                                    disabled={myIndex === totalLength - 1 || readOnly}
                                    size="small"
                                    onClick={() =>
                                        documentKeyTermsService.shiftKeyTermDown(keyTerm.termName)
                                    }
                                >
                                    <ArrowDownward />
                                </IconButton>
                            </Grid>
                        )}
                    </Grid>
                </Grid>
                <Grid item xs={12}>
                    <Box>
                        <Divider />
                    </Box>
                    <Box
                        paddingTop={1}
                    >
                        <EditableSummary
                            importedText={importedText}
                            importedTextChanges={importedTextChanges}
                            data-testid={`key-term-summary-${keyTerm.termName}`}
                            expandSummary={showExpandedSummaries}
                            defaultValue={keyTerm.summary}
                            onChangeValue={(v) =>
                                documentKeyTermsService.updateKeyTermSummary(keyTerm.termName, v)
                            }
                            readOnly={readOnly}
                        />
                    </Box>
                </Grid>
                <Grid item xs={12}>
                    <Box paddingTop={1}>
                        {entries.length > 0 ? (
                            entries.map(([page, annotationIds]) => {
                                let numPage = parseInt(page);
                                return (
                                    <Chip
                                        sx={{ marginLeft: "5px", marginRight: "5px" }}
                                        size="small"
                                        label={`page ${numPage + 1}`}
                                        color="info"
                                        variant="outlined"
                                        onClick={() => mutateState<KeyTermsState>({
                                            scrollToPage: numPage,
                                            activeAnnotationIds: annotationIds
                                        })}
                                    />
                                );
                            })
                        ) : (
                            <span>
                                No References
                            </span>
                        )}
                    </Box>
                </Grid>
            </Grid>
            {needConfirm && (
                <ConfirmationDialog
                    title="Confirmation"
                    confirmationText={`Are you sure you want to delete key term "${keyTerm.termName}"`}
                    onConfirmed={() => {
                        documentKeyTermsService.removeKeyTerm(keyTerm.termName)
                        setNeedConfirm(false);
                    }}
                    onCancelled={() => {
                        setNeedConfirm(false);
                    }}
                />
            )}
            <KeyTermDetailModal
                defaultKeyTerm={keyTerm}
                open={editOpen === myIndex}
                onClose={() => setEditOpen(undefined)}
            />
        </ListItem>
    );
};

export default KeyTermListItem;