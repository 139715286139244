import { useCallback, useMemo } from "react";
import PagesEditor from "./PagesEditor";
import { Box, CircularProgress, Typography, Stack, Button } from "@mui/material";
import { TakerDocumentUploadAnalysis } from "../../../redux/models/dataModelTypes";
import {
    useGetTakerDocumentUploadAnalysisPayloadQuery
} from "../../../redux/services/taker";
import { DocumentAnnotation, DocumentHighlight, KeyTerm } from "../../../types/taker/documentkeyterms.generated";
import { useKeyTermGroupState } from "../../../containers/TakerDocumentState/KeyTermGroupState";
import { useWidgetState } from "../../../containers/WidgetWrapper/wrapper";
import { KeyTermsState } from "../../../containers/WidgetWrapper/states";

const mapToHighlight = (elementId: string): DocumentHighlight => {
    const numParts = elementId.split('-').length;
    let elementType: "WORD" | "LINE" | "TEXTBOX" | null = null;
    if (numParts === 2) {
        elementType = "TEXTBOX";
    } else if (numParts === 3) {
        elementType = "LINE";
    } else if (numParts === 4) {
        elementType = "WORD";
    }
    return {
        elementId,
        elementType
    } as DocumentHighlight;
};

interface DocumentAnnotatorProps {
    readOnly: boolean;
    documentWidth: number;
    fileUploadItemId: string;
    takerDocumentUploadAnalysis: TakerDocumentUploadAnalysis;
}

const DocumentAnnotatorComponent = ({
    readOnly,
    documentWidth,
    fileUploadItemId,
    takerDocumentUploadAnalysis
}: DocumentAnnotatorProps) => {
    const { documentKeyTermsService } = useKeyTermGroupState();
    const {
        data: analysisPayload,
        isSuccess,
        isError,
        isFetching
    } = useGetTakerDocumentUploadAnalysisPayloadQuery(takerDocumentUploadAnalysis.id);
    const { getState } = useWidgetState();
    const panelViewMode = getState<KeyTermsState>().panelViewMode;

    const lexicalDocumentIdentifier = takerDocumentUploadAnalysis.data["file_item_id"];
    const aiEnabled = takerDocumentUploadAnalysis.data['enable_key_term_tagging'];
    const jobFailureMessage = takerDocumentUploadAnalysis.data['job_failure'];

    const isFileAnalysisPending = useMemo(() =>
        !takerDocumentUploadAnalysis || (takerDocumentUploadAnalysis.state == "PENDING_GENERATION"),
        [takerDocumentUploadAnalysis]
    );

    const lexicalDocument = useMemo(() => {
        if (documentKeyTermsService.lexicalDocuments) {
            for (const ld of documentKeyTermsService.lexicalDocuments) {
                if (ld.identifier === fileUploadItemId) {
                    return ld;
                }
            }
        }
    }, [fileUploadItemId, documentKeyTermsService.lexicalDocuments]);

    const newKeyTerms = useMemo(() => {
        if (!analysisPayload) {
            return [];
        }

        let annotationsByTermName: Record<string, DocumentAnnotation[]> = {};
        let categoriesByTermName: Record<string, string[]> = {};
        if (analysisPayload['annotations']) {
            for (let page = 0; page < analysisPayload['annotations'].length; page++) {
                const annotations = analysisPayload['annotations'][page];
                if (annotations) {
                    for (const annotation of annotations) {
                        const termName = annotation['term'];
                        if (!annotationsByTermName[termName]) {
                            annotationsByTermName[termName] = [];
                        }

                        let highlightIds: string[] = annotation['id_group'] || [];

                        // filter by existing 
                        let existingSet = new Set();
                        for (const anno of annotationsByTermName[termName]) {
                            for (const dh of anno.documentHighlights) {
                                existingSet.add(dh.elementId);
                            }
                        }

                        highlightIds = highlightIds.filter(hid => !existingSet.has(hid))
                        if (highlightIds.length > 0) {
                            annotationsByTermName[termName].push({
                                annotationId: window.crypto.randomUUID(),
                                lexicalDocumentIdentifier,
                                documentHighlights: highlightIds.map(mapToHighlight),
                                page
                            });
                        }

                        if (!categoriesByTermName[termName]) {
                            categoriesByTermName[termName] = [];
                        }

                        const tag = annotation['tag']
                        if (!categoriesByTermName[termName].includes(tag)) {
                            categoriesByTermName[termName].push(tag);
                        }
                    }
                }
            }
        }

        const summaries: Record<string, string> = analysisPayload['summaries'] || {}

        let keyTerms: KeyTerm[] = [];
        const nowTs = new Date().getTime();
        for (const [termName, annotations] of Object.entries(annotationsByTermName)) {
            keyTerms.push({
                identifier: window.crypto.randomUUID(),
                categories: categoriesByTermName[termName],
                documentAnnotations: annotations,
                summary: summaries[termName] || "",
                termName,
                lastUpdatedSummaryAt: nowTs,
                lastUpdatedDocumentReferencesAt: nowTs,
                locked: false
            })
        }
        return keyTerms;
    }, [isFetching, analysisPayload]);

    const createFromAiResults = useCallback(() => {
        if (!readOnly) {
            documentKeyTermsService.createKeyTerms(newKeyTerms);
            documentKeyTermsService.createLexicalDocument({
                lexicalPages: analysisPayload['lexical_pages'],
                identifier: lexicalDocumentIdentifier,
                pageMetadata: analysisPayload['page_metadata']
            });
        }
    }, [
        readOnly,
        documentKeyTermsService,
        analysisPayload && analysisPayload['lexical_pages'],
        analysisPayload && analysisPayload['page_metadata'],
        newKeyTerms,
        lexicalDocumentIdentifier
    ]);

    const createFromResults = useCallback(() => {
        if (!readOnly) {
            documentKeyTermsService.createLexicalDocument({
                lexicalPages: analysisPayload['lexical_pages'],
                identifier: lexicalDocumentIdentifier,
                pageMetadata: analysisPayload['page_metadata']
            });
        }
    }, [
        readOnly,
        documentKeyTermsService,
        analysisPayload && analysisPayload['lexical_pages'],
        analysisPayload && analysisPayload['page_metadata'],
        lexicalDocumentIdentifier
    ]);

    if (isFetching || isFileAnalysisPending) {
        return <CircularProgress />;
    } else if (isError) {
        return (
            <Box>
                {!!jobFailureMessage ? (
                    `A fatal error happened while processing the file - ${jobFailureMessage}`
                ) : (
                    `A fatal error happened while processing the file`
                )}
            </Box>
        );
    } else if (isSuccess) {
        const startHighlightButton = (
            <Button
                disabled={readOnly}
                onClick={createFromResults}
            >
                Start Highlighting
            </Button>
        );
        return (
            !lexicalDocument ? (
                <Box
                    sx={{ backgroundColor: "white" }}
                    border="1px solid rgb(195, 195, 195)"
                    borderRadius={1}
                    padding="5%"
                    height="calc(100% - 5px)"
                    display="block"
                    alignItems="center"
                    alignContent="center"
                    textAlign="center"
                >
                    {aiEnabled ? (
                        <>
                            <Stack>
                                {startHighlightButton}
                                <Typography variant="subtitle2">
                                    or
                                </Typography>
                                <Button
                                    disabled={readOnly}
                                    onClick={createFromAiResults}
                                >
                                    Start Highlighting with AI results
                                </Button>
                            </Stack>
                        </>
                    ) : (
                        startHighlightButton
                    )}
                </Box>
            ) : (
                <PagesEditor
                    readOnly={readOnly}
                    documentWidth={documentWidth}
                    lexicalDocument={lexicalDocument}
                />
            )
        );
    }
    return (
        <Box>
            <em>something unknown occurred</em>
        </Box>
    );
}

export default DocumentAnnotatorComponent;