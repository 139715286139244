import { useState } from "react";
import { useNavigate } from "react-router-dom";
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import { Builder } from "../../redux/models/dataModelTypes";
import { Edit, OpenInFull } from "@mui/icons-material";
import { useListBuildersQuery } from "../../redux/services/builder";
import PagedTable from "../../components/tables/pagedTable";
import { useUserScopedAppData } from "../../containers/UserScopedAppData/UserScopedAppData";
import { useSelector } from "../../redux/reduxUtils/functions";
import { RootReducerType } from "../../redux/models/reduxTypes";
import { CrudButtonGroup } from "../../components/buttons/crudButtonGroup";

interface Props {
  onOpenBuilder: (bm: Builder) => void;
}

const MyRecentBuilders = ({ 
  onOpenBuilder
}: Props) => {
  const [page, setPage] = useState<number>(1);
  const [limit, setLimit] = useState<number>(5);
  const { selectedOrgId } = useUserScopedAppData();
  const navigate = useNavigate();

  const {
    data: builders,
    isFetching,
    isLoading,
  } = useListBuildersQuery({
    page, 
    limit, 
    organizationId: selectedOrgId,
    ownedByUser: (!!selectedOrgId ? false : true)
  });

  const { user } = useSelector((state: RootReducerType) => state.auth);
  const hasBuilderRole = user?.roles.includes('BUILDER');
  let finalBuildersList;
  if(builders) {
    finalBuildersList = {...builders};
    if(!!builders && !hasBuilderRole) {
      finalBuildersList.data = builders?.data?.filter((b: Builder) => { 
        if(b.status === "PUBLISHED" ) {
          return true;
        }
      });
    }
  } else {
    finalBuildersList = undefined
  }
  return (
    <PagedTable
      isFetching={isFetching}
      isLoading={isLoading}
      paginatedResponse={finalBuildersList}
      tableSpan={5}
      rowMapper={(b: Builder) => (
        <TableRow
          key={b.id}
          sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
        >
          <TableCell>
            {b.name ? (
              b.name
            ) : (
              "no name"
            )}
          </TableCell>
          {hasBuilderRole && (
            <TableCell>{b.status}</TableCell>
          )}
          <TableCell>{new Date(b.createdAt).toLocaleString()}</TableCell>
          <TableCell>
            <CrudButtonGroup 
              buttons={(user && b.ownerUserId === user.id) ? [
                {
                  icon: (<Edit fontSize="small"/>),
                  handleClick: () => {
                    navigate(`/mainBuilder/${b.id}`);
                  }
                },
                {
                  icon: (<OpenInFull fontSize="small"/>),
                  handleClick: () => {
                    onOpenBuilder(b)
                  }
                }
              ] : [
                {
                  icon: (<OpenInFull fontSize="small"/>),
                  handleClick: () => {
                    onOpenBuilder(b)
                  }
                }
              ]} 
            />
          </TableCell>
        </TableRow>
      )}
      headers={
        hasBuilderRole ?
        [
          "Label",
          "Status",
          "Created",
          ""
        ] : [
          "Label",
          "Created",
          ""
        ]
      }
      page={page}
      limit={limit}
      setPage={setPage}
      setLimit={setLimit}
    />    
  );
}

export default MyRecentBuilders
